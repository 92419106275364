<template>
  <div class="impressum-wrapper">
    <div class="header">
      <div class="mobile-wrapper">
        <div class="header-background" :style="{backgroundImage: 'url(https://ebikeworldfederation.com/images/impressum/SD_EBWM_Headerbild_Impressum_01.jpg)'}">
          <div class="header-title-container">
            <h1>{{ $t("press") }}</h1>
          </div>
        </div>
      </div>
    </div>

    <v-main class="impresum-content-wrapper">
      <v-container fluid>
        <v-layout class="align-center justify-center">
          <v-flex xs12 sm12 md11 lg10 xl9>
            <div class="impressum-body">
              <div class="impressum-content impressum-font">
                <div>
                  <div>
                    E-Bike Federation GmbH<br/>
                    Sillian 25i<br/>
                    A-9920 Sillian
                  </div>

                  <h2>{{ $t("impressum.editor") }}</h2>
                  E-Bike World Federation <br/>
                  Sillian 25i <br/>
                  A-9920 Sillian <br/>
                  Austria/ Tirol <br/>
                  Fon: <a href="tel:0043(0)484220607">+ 43 (0) 4842 20607</a> <br/>
                  E-Mail: <a href="mailto:info@ebikeworldfederation.com">info@ebikeworldfederation.com</a> <br/><br/>

                  <span v-if="isItalian()">
                    L'associazione è pubblica e non orientata al profitto. L'impegno dei membri è volontario.<br/><br/>

                    Lo scopo e lo scopo dell'associazione E-Bike World Federation è di fornire informazioni, nonché la
                    promozione e il coordinamento di progetti con biciclette con supporto elettrico e la loro fornitura
                    di energia (verde). <br/><br/>

                    Numero ZVR: 1740897906 <br/><br/>

                    Rappresentante / fondatore aziendale: Dr. Markus Mitterdorfer, Werner Mitterdorfer
                  </span>
                  <span v-else-if="isEnglish()">
                    The association is public and not geared to profit. The commitment of the members is voluntary.<br/><br/>

                    The object and purpose of the association E-Bike World Federation is to provide information about,
                    as well as the promotion and coordination of projects with bicycles with electrical support and
                    their supply by (green) energy. <br/><br/>

                    ZVR number: 1740897906 <br/><br/>

                    Corporate representative / founder: Dr. Markus Mitterdorfer, Werner Mitterdorfer
                  </span>
                  <span v-else>
                    Der Verein ist öffentlich tätig und nicht auf Gewinn ausgerichtet. Der Einsatz der Mitglieder ist ehrenamtlich.<br/><br/>

                    Gegenstand und Zweck des Vereins E-Bike World Federation ist es, Aufklärungsarbeit über, sowie die
                    Förderung und die Koordinierung von Projekten mit Fahrräder/n mit elektrischer Unterstützung und
                    deren Versorgung durch (grüne) Energie zu leisten.<br/><br/>

                    ZVR-Zahl: 1740897906<br/><br/>

                    Organschaftliche Vertreter/ Gründer: Dr. Markus Mitterdorfer, Werner Mitterdorfer
                  </span>
                </div>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
  export default {
    name: 'impressum',
    data() {
      return {
      };
    },
    methods: {
      isGerman() {
        return this.$store.getters.getLanguage === 'DE';
      },
      isEnglish() {
        return this.$store.getters.getLanguage === 'EN';
      },
      isItalian() {
        return this.$store.getters.getLanguage === 'IT';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../assets/scss/variables";
  @import "../assets/scss/layout";

  .impressum-wrapper {
    font-family: $default-font;
    color: $primary-text;

    .header {
      position: relative;
      height: 500px;
      .header-background {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 500px;
        position: fixed;
        z-index: 0;

        .header-title-container {
          position: absolute;
          bottom: 50px;
          left: 200px;
          @include MQ(XS) {
            left: 10px;
            bottom: 25px;
          }
          @include MQ(S) {
            left: 50px;
            bottom: 25px;
          }
          @include MQ(M) {
            left: 50px;
            bottom: 25px;
          }
          @include MQ(L) {
            left: 150px;
            bottom: 35px;
          }
          h1 {
            font-style: italic;
            color: white;
            font-size: 72px;
            line-height: 72px;
            text-align: left;
            span {
              color: $primary;
            }
            @include MQ(XS) {
              font-size: 38px;
              line-height: 38px;
              margin-bottom: 10px;
            }
            @include MQ(S) {
              font-size: 48px;
              line-height: 48px;
              margin-bottom: 10px;
            }
            @include MQ(M) {
              font-size: 48px;
              line-height: 48px;
            }
            @include MQ(L) {
              font-size: 62px;
              line-height: 62px;
            }
          }
        }
      }
    }

    .impresum-content-wrapper {
      position: relative;
      background-color: white;
      color: $primary-text;

      .impressum-body {
        position: relative;
        background-color: white;
        width: 100%;
        height: 100%;
        padding-top: 10px;

        .law {
          margin-top:10px;
          margin-bottom: 20px;
          font-weight: 100;
        }

        .impressum-letter {
          background-color: #fff !important;
          width: 100%;
          min-height: 250px;
          display: block;
          .lines {
            border-left: 1px solid #ffaa9f;
            border-right: 1px solid #ffaa9f;
            width: 4px;
            float: left;
            height: 100%;
            margin-left: 27px;
            position: absolute;
            @include MQ(S) {
              width: 3px;
            }
          }
        }
        .impressum-letter ul {
          margin: 0 !important;
          padding: 0 !important;
          border: 1px solid #dedede;
          width: 100%;
          position: relative;
          font-size: 18px;
          font-family: $default-font;
          &>li {
            cursor: pointer;
            list-style: none;
            border-bottom: 1px dotted #ccc;
            height: auto;
            padding: 5px 5px 5px 35px;
            line-height: 18px;
            &:hover {
              background-color: #f0f0f0;
              -webkit-transition: all 0.2s;
              -moz-transition:    all 0.2s;
              -ms-transition:     all 0.2s;
              -o-transition:      all 0.2s;
            }
            @include MQ(S) {
              line-height: 11px;
            }
          }
          @include MQ(S) {
            font-size: 11px;
          }
        }
      }

      .impressum-content {
        min-height: 600px;
        text-align: left;
        font-size: 20px;
        @include MQ(S) {
          font-size: 15px;
        }

        h2 {
          margin-top: 1rem;
          margin-bottom: 1rem;
          font-weight: 500;

          @include MQ(S) {
            font-size: 1.5rem;
          }
        }

        a {
          text-decoration: none;

          &:hover {
            text-decoration: none;
            color: $primary;
          }
        }
      }
    }
  }
</style>
